<template>
  <v-container class="my-6">
    <v-skeleton-loader
      v-if="loading"
      type="article, list-item-two-line, list-item-two-line, actions"
    ></v-skeleton-loader>
    <v-card v-else class="px-6">
      <v-row>
        <v-col class="pb-0" cols="12">
          <v-btn
            small
            class="ma-2"
            color="secondary"
            outlined
            @click="$router.back()"
          >
            <v-icon>mdi-chevron-left</v-icon> voltar
          </v-btn>
        </v-col>
      </v-row>
      <!-- Titulo, subtitulo e created_at -->
      <v-row class="mt-0" align="center">
        <v-col cols="12" md="7" order="2" order-sm="2" order-md="1">
          <v-card-title
            tag="h1"
            class="font-weight-bold text-h5 black--text pl-0"
          >
            {{ vaga.titulo }}
          </v-card-title>
          <v-card-subtitle
            tag="h2"
            class="black--text font-weight-medium text-body-1 pl-0"
            v-if="vaga && vaga.cliente"
          >
            {{ vaga.cliente }}
          </v-card-subtitle>
        </v-col>
        <v-col
          class="pb-0 pb-md-3"
          cols="12"
          md="5"
          order="1"
          order-sm="1"
          order-md="2"
        >
          <div class="d-flex justify-end">
            <p class="ma-0 pa-0 text-caption">
              Publicado em {{ vaga.created_at | dateTimeFormatBr(null, "LLL") }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="ma-0" v-if="vaga && vaga.categoria">
        <v-col class="pa-0" cols="12">
          <p class="ma-0 pa-0">{{ vaga.categoria }}</p>
        </v-col>
      </v-row>
      <!-- Tags cargo, endereco, tipo_vaga e pcd -->
      <v-row>
        <v-col cols="12">
          <div
            class="d-flex flex-column flex-sm-row flex-wrap align-start align-sm-center justify-start"
          >
            <p class="mx-2" v-if="vaga && vaga.cargo">
              <v-icon>mdi-domain</v-icon> {{ vaga.cargo }}
            </p>
            <p class="mx-2" v-if="vaga && vaga.endereco">
              <v-icon>mdi-map-marker</v-icon> {{ vaga.endereco.cidade }} -
              {{ vaga.endereco.uf }}
            </p>
            <p class="mx-2" v-if="vaga && vaga.tipo_vaga">
              <v-icon>mdi-briefcase-variant</v-icon> {{ vaga.tipo_vaga }}
            </p>
            <p class="mx-2" v-if="vaga && vaga.local_vaga">
              <v-icon>mdi-office-building-marker-outline</v-icon>
              {{ vaga.local_vaga | capitalize() }}
            </p>
            <p class="mx-2" v-if="vaga && vaga.pcd">
              <v-icon>mdi-wheelchair</v-icon> Vaga disponível também para PcD
            </p>
          </div>
        </v-col>
      </v-row>
      <!-- Descricao, Informacoes adicionais, carga horaria, salario, beneficios -->
      <v-row>
        <v-col cols="12" v-if="vaga && vaga.descricao">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">Descrição da vaga</h3>
            <p class="mb-1 text-body-2 font-weight-regular">
              {{ vaga.descricao }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-if="vaga && vaga.informacoes_adicionais">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">Informações adicionais</h3>
            <p class="mb-1 text-body-2 font-weight-regular">
              {{ vaga.informacoes_adicionais }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-if="vaga && vaga.carga_horaria">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">Carga horária semanal</h3>
            <p
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              {{ vaga.carga_horaria }} horas
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-if="vaga && vaga.salario">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">Salário</h3>
            <p
              v-if="vaga && vaga.salario.valor_salario == 'A combinar'"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              A combinar
            </p>
            <p
              v-else-if="vaga && vaga.salario.valor_salario"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              R${{
                vaga.salario.valor_salario | numberFormatBrWithoutCurrency()
              }}
              por
              {{ vaga.salario.tipo_remuneracao == "MES" ? "Mês" : "Hora" }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-if="vaga && vaga.competencias">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">Competências</h3>
            <div class="d-flex flex-row flex-wrap align-center">
              <p
                v-for="(item, i) in vaga.competencias"
                :key="item"
                class="mb-1 text-body-2 font-weight-regular"
              >
                {{ item
                }}<span v-if="i < vaga.competencias.length - 2">, &nbsp;</span>
                <span v-if="i == vaga.competencias.length - 2"> e&nbsp;</span>
                <span v-if="i == vaga.competencias.length - 1">.</span>
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">
              Habilidades e requisitos necessários
            </h3>
            <p
              v-if="vaga && vaga.formacao"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              {{ vaga.formacao }}
            </p>
            <p
              v-if="vaga && vaga.tempo_experiencia"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              {{
                vaga.tempo_experiencia == "Sem experiência"
                  ? vaga.tempo_experiencia
                  : `${vaga.tempo_experiencia} de experiência`
              }}
            </p>
            <p
              v-if="vaga && vaga.veiculo_proprio"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              Necessário ter veículo próprio
            </p>
            <p
              v-if="vaga && vaga.habilitacao"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              Necessário ter habilitação &nbsp;
              <span v-if="vaga && vaga.tipos_habilitacao">
                {{ vaga.tipos_habilitacao }}
              </span>
            </p>
            <p
              v-if="vaga && vaga.disponibilidade_viagem"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              Ter disponibilidade para viagem
            </p>
            <p
              v-if="vaga && vaga.idiomas"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              Idiomas: {{ vaga.idiomas }}
            </p>
            <p
              v-if="vaga && vaga.cursos"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              Cursos: {{ vaga.cursos }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" v-if="vaga && vaga.beneficios">
          <div class="d-flex flex-column">
            <h3 class="text-h6 font-weight-bold">Benefícios</h3>
            <p
              v-for="(item, i) in vaga.beneficios"
              :key="i"
              class="mb-1 text-body-2 font-weight-regular d-flex flex-row align-center"
            >
              <v-icon class="mr-2">mdi-checkbox-marked-circle-outline</v-icon>
              {{ item }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center">
            <v-btn class="ma-2" color="primary">Candidatar-se</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "VagaDetalhes",
  data() {
    return {
      loading: true,
    };
  },
  created() {
    const uuid = this.$route.params.uuid;
    this.$store.dispatch("resVagas/getVagaDetail", uuid).finally(() => {
      this.loading = false;
    });
  },
  computed: {
    vaga: {
      get() {
        return this.$store.getters["resVagas/getVaga"];
      },
    },
  },
};
</script>

<style></style>
